import { createRouter, createWebHashHistory } from "vue-router";
const LoginView = () => import('@router/views/Login' /* webpackChunkName: "Login" */);
const EditorView = () => import('@router/views/Editor' /* webpackChunkName: "Home" */);
const DashboardView = () => import('@router/views/Dashboard' /* webpackChunkName: "Profile" */);
const AudioManagerView = () => import('@router/views/AudioManager' /* webpackChunkName: "AudioManager" */);
const NotFoundView = () => import('@router/views/404' /* webpackChunkName: "404" */);


const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: DashboardView,
    meta:{

    }
  },
  {
    path: "/editor",
    name: "Editor",
    component: EditorView,
    meta:{

    }
  },{
    path: "/audio-manager",
    name: "AudioManager",
    component: AudioManagerView,
    meta:{

    }
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    meta: {
      hideFromAlreadyLoggedIn: true
    }
  },
  {
    path: "/404",
    name: "404",
    component: NotFoundView,
    meta: {}
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
