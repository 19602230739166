/* eslint-disable */

/**
 * Mutations
 * @type {string}
 */
const SET_USERNAME = 'SET_USERNAME';

/**
 *
 * @type {{drafts: Array}}
 */
export const state = {
  user: {
    username: null
  }
};

/**
 *
 * @type {{}}
 */
export const mutations = {
  [SET_USERNAME](state, username) {
    state.user.username = username
  }
};

/**
 *
 * @type {{allDrafts: (function(*): (Array|*))}}
 */
export const getters = {
  getUsername: (state) => state.user.username
};

/**
 *
 * @type {{init({state: *, dispatch: *}), getDrafts({commit: *}): void}}
 */
export const actions = {
  init({ state, dispatch }){
  },
  /**
   *
   * @param commit
   */
  setUsername({ commit }, payload) {
    commit(SET_USERNAME, payload);
  }
};
