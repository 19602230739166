<template>
  <div class="app">
    <router-view />
  </div>
</template>

<style lang="scss">
@font-face {
  font-family: 'Museo Sans Rounded';
  src: url(~@src/assets/fonts/MuseoSansRounded.woff) format('woff');
}
html, body {
  font-family: Museo Sans Rounded;
  width: 100vw;
  height: 100vh;
  padding: 0 !important;
  margin: 0 !important;
  outline: none;
  background-color: #f9fafb;

}

</style>
