import { createStore, createLogger } from 'vuex';
import modules from './modules';

const ENV = process.env.NODE_ENV;

const store = createStore({
  modules,
  plugins: ENV !== 'production' ? [createLogger()] : [],
  strict: ENV !== 'production'
});


/**
 * Automatically  run the `init` action for every module, if exists.
 */
for (const moduleName of Object.keys(modules)) {
  if (modules[moduleName].actions && modules[moduleName].actions.init) {
    store.dispatch(`${moduleName}/init`);
  }
}

export default store;
